$px-bright-blue: #2587F7;
$theme-colors: (primary: $px-bright-blue) !default;

$font-family-sans-serif: 'Nunito Sans';
$font-family-monospace:'Nunito Sans';
$font-family-base: $font-family-sans-serif !default;

$font-size-base: .875rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * .875 !default;

$h3-font-size: 18px;
$h4-font-size: 14px;
$h5-font-size: 14px;

// $font-size-large: 14px;
// $font-size-mid: 12px;
// $font-size-small: 11px;

// $gray-100: #f8f9fa !default;
$gray-200: #ececec;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
$gray-600: #6C7476 !default;
$gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;
// $black: #000 !default;

$error: #F84C3B;

@import "bootstrap";