@import "../../styles/Variables.scss";

.week-table {
  margin-left: -12px;
  margin-right: -12px;
  table-layout: fixed;

  thead {
    th {
      padding-top: 0px;
      padding-bottom: 0px;
      border-bottom: none !important;

      >div {
        background-color: $gray-600;
        color: $white;
        border-radius: 3px;
        font-weight: bold;
        padding: 4px;
      }
    }
  }

  tbody {
    tr {
      td {
        // label {
        //   display: none;
        // }

        border-top: none !important;
        padding-top: 4px;
        padding-bottom: 0px;

        &:last-child {
          padding-bottom: 4px;
        }
      }
    }
  }

  tfoot {
    td {
      border-top: none;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

}